<template>
	
	<div>

		<div v-if="!has_content" class="card-body p-4">
			Add content to see a preview.
		</div>

		<div v-else>

			<div v-for="( block, block_key ) in content" :key="'block-' + block_key" class="border-bottom p-4 position-relative bg-white">

				<!-- <p class="badge bg-info position-absolute top-0 start-0 d-flex align-items-center" style="border-radius: 0 0 3px 0;">
					<span class="material-icons-outlined me-2">info</span> Recommendations 
					<span class="badge-hover bg-info text-start">
						<span class="fw-bold d-block mb-2">Recommended block:</span>
						{{ block.block }} block

						<span class="fw-bold d-block mb-2 mt-3">Content:</span>
						<span v-if="block.block == 'Hero' && block.description.split( ' ' ).length > 50">The content in this block is long. You should keep the content in your hero to less than 50 words making it more digestable to users and improve the design of the page.</span>
						<span v-if="( block.block == 'Carousel' || block.block == 'Items' ) && block.description.split( ' ' ).length > 50">The content in this block is long. You should keep the content to less than 50 words making it more digestable to users and improve the design of the page.</span>
						<span v-else-if="block.description.split( ' ' ).length > 100">The content in this block is long. You should break this up with additional headings, keeping each section less than 100 words to make it more digestable to users and improve the design of the page.</span>
						<span v-else>The content in this block looks good. The length should be easily digestable for users and the design should look great.</span>
					</span>
				</p> -->

				<div class="container">
					<div class="row">
						<div class="col">
							<div class="small py-4">

								<div class="row align-items-center justify-content-between">
									<div class="col-5" :class="block_key % 2 != 0 && !['Form'].includes( block.block ) ? 'order-1' : ''">
										<h2 v-if="block.title">{{ block.title }}</h2>
										<p v-if="block.description" v-html="block.description" class="mb-0"></p>

										<div v-if="block.items.length && !block.items_has_description" class="mt-4">
											<div v-for="( item, item_key ) in block.items" :key="'block-' + block_key + '-item-' + item_key" class="d-flex align-items-center mb-1">
												<span class="material-icons-outlined me-2">check_circle</span>
												{{ item.title }}
											</div>
										</div>

										<ul v-if="block.is_contact" class="mt-4">

											<div v-if="block.address" class="d-flex align-items-center">
												<span class="material-icons-outlined me-2">location_on</span>
												{{ block.address }}
											</div>

											<div v-if="block.email" class="d-flex align-items-center">
												<span class="material-icons-outlined me-2">email</span>
												{{ block.email }}
											</div>

											<div v-if="block.phone" class="d-flex align-items-center">
												<span class="material-icons-outlined me-2">phone</span>
												{{ block.phone }}
											</div>

										</ul>

										<div v-if="block.buttons.length">
											<button v-for="(btn, btn_key) in block.buttons" :key="'block-' + block_key + '-btn-' + btn_key" type="button" class="btn btn-outline-primary mt-4 me-3">{{ btn.label }}</button>
										</div>
									</div>

									<div v-if="(!block.items.length || !block.items_has_description) && !['Form'].includes( block.block )" class="col col-6">
										<div class="position-relative">
											<div class="ratio ratio-1x1"></div>
											<img src="https://placeholder.areoi.io/600x600" class="position-absolute top-50 start-50 translate-middle w-100">
										</div>
									</div>

									<div v-else-if="['Form'].includes( block.block )" class="col">
										<div class="position-relative">
											<div class="card">
												<div class="card-body">
													<div class="mb-3">
														<label class="form-label">Full name</label>
														<input type="text" name="fullname" class="form-control">
													</div>

													<div class="mb-3">
														<label class="form-label">Email</label>
														<input type="text" name="email" class="form-control">
													</div>

													<div class="mb-3">
														<label class="form-label">Phone</label>
														<input type="text" name="phone" class="form-control">
													</div>

													<button type="button" class="btn btn-outline-primary btn-lg w-100">Submit enquiry</button>

												</div>
											</div>
										</div>
									</div>
								</div>

								

								<div v-if="block.items.length && block.items_has_description" class="mt-4 position-relative">
									<div class="row" :class="['Logos'].includes( block.block ) ? 'row-cols-5' : 'row-cols-3'">
										<div v-for="( item, item_key ) in block.items" :key="'block-' + block_key + '-item-' + item_key" class="col mb-4">
											<div class="card h-100">
												<div class="position-relative">
													<div class="ratio ratio-16x9"></div>
													<img src="https://placeholder.areoi.io/400x300" class="position-absolute top-50 start-50 translate-middle w-100 h-100">
												</div>
												<div v-if="!['Media', 'Logos'].includes( block.block )" class="card-body p-4">
													<h4 class="mb-2">{{ item.title }}</h4>
													<p v-if="item.description" v-html="item.description" class="mb-0"></p>

													<div v-if="item.items_inr && item.items_inr.length" class="position-relative mt-4">
														
														<div v-for="( item, item_key ) in item.items_inr" :key="'block-' + block_key + '-item-' + item_key" class="d-flex align-items-center mb-1">
															<span class="material-icons-outlined me-2">check_circle</span>
															{{ item.title }}
														</div>
													</div>

													<button v-if="item.btn_label" type="button" class="btn btn-outline-primary btn-sm mt-4 me-3">{{ item.btn_label }}</button>
													<button v-else-if="item.btn_url" type="button" class="btn btn-outline-primary btn-sm mt-4 me-3">Read More</button>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
export default {
	name: 'components.html_to_block_formatted',
	props: {
		original_content: String,
		content: [Object, Array, String],
		page: [Array, Object]
	},
	computed: {
		has_content()
		{
			return this.original_content ? this.original_content.replace(/(<([^>]+)>)/gi, '') : null
		}
	}
}
</script>

<style>

</style>
