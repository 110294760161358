<template>
	
	<template v-for="( page ) in pages" :key="'page-' + page.ID">
		<router-link :to="{ name: 'site.planning.page', params: { page_id: page.ID } }" class="planning-page-link p-4 py-2 mb-0 d-block" :class="page.ID == $route.params.page_id ? 'active' : ''">
			{{ page.post_title }}
		</router-link>

		<div v-if="page.children" :class="page.ID != 4 ? 'ps-4' : ''">
			<tree_page_links :pages="page.children" />
		</div>

	</template>

</template>

<script>
import tree_page_links from '@/components/tree_page_links'

export default {
	name: 'components.tree_page_links',
	props: {
		pages: Array
	},
	components: {
		tree_page_links
	},
	data() {
		return {
			
		}
	},
	mounted()
	{
		
	},
	methods: {
		
	}
}
</script>

<style>

</style>
