<template>
	
	<div class="setup-chat-message d-flex align-items-start mb-3" :class="align_class">

		<div v-if="author != 4" class="border rounded-circle overflow-hidden position-relative bg-primary border-dark" :class="img_class" style="flex: 0 0 40px;">
			<div class="ratio ratio-1x1"></div>
			<img v-if="author == 1" src="@/assets/img/bella.png" class="img-fluid position-absolute top-0">
			<img v-if="author == 2" src="@/assets/img/leo.png" class="img-fluid position-absolute top-0">
			<img v-if="author == 3" src="@/assets/img/pablo.png" class="img-fluid position-absolute top-0">
		</div>

		<div style="min-width: 120px;" :style="'max-width: ' + comment_width + '%'">
			<div class="alert mb-0 w-auto" :class="card_class" style="border-bottom-width: 3px !important;">

				<span 
				v-if="type == 1" 
				class="material-icons-outlined position-absolute text-white" 
				style="font-size: 40px; top: 0px; left: -23px; text-shadow: -1px 1px 0 #ddd;"
				>arrow_left</span>

				<span 
				v-else-if="type == 3" 
				class="material-icons-outlined position-absolute" 
				style="font-size: 40px; top: 0px; left: -23px; color: #f3d6e0; text-shadow: -1px 1px 0 #e8adc1;"
				>arrow_left</span>

				<span 
				v-else-if="type == 4" 
				class="material-icons-outlined position-absolute" 
				style="font-size: 40px; top: 0px; left: -23px; color: #e3d1fb; text-shadow: -1px 1px 0 #c6a2f7;"
				>arrow_left</span>

				<span 
				v-else 
				class="material-icons-outlined position-absolute" 
				style="font-size: 40px; top: 0px; right: -23px; color: #ffe4e4; text-shadow: 1px 1px 0 #ffc8c8;"
				>arrow_right</span>

				<span v-if="type != 2" v-html="content"></span>
				<span v-else>{{ content }}</span>

			</div>

			<div v-if="include_resend" class="pt-2">
				<btn_submit 
					label="Resend email" 
					icon="email"
					btn_class="btn btn-link text-decoration-none"
					:icon_before="true"
					:loading="verify_loading" 
					@click.prevent="resend_verify"
				/>
			</div>

			<div v-if="include_reset" class="pt-2">
				<btn_submit 
					label="Reset password" 
					icon="email"
					btn_class="btn btn-link text-decoration-none"
					:icon_before="true"
					:loading="reset_loading" 
					@click.prevent="reset_password"
				/>
			</div>

			<div v-if="include_edit" class="text-end">
				<btn_submit 
					label="Edit" 
					icon="edit"
					btn_class="btn btn-link text-decoration-none px-0"
					:icon_before="true" 
					@click.prevent="edit"
				/>
			</div>

			<div v-if="include_countdown" class="pt-2 small">
				This shouldn't take longer than <countdown :time="( countdown ? countdown : 180 )" :start="true" />.
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import btn_submit from '@/components/btn_submit'
import countdown from '@/components/countdown'
import user_service from '@/services/user_service'

export default {
	name: 'components.chat_message',
	props: {
		content: String,
		type: [String,Number],
		author: [String,Number],
		include_resend: Boolean,
		include_reset: Boolean,
		include_countdown: Boolean,
		include_edit: Boolean,
		email: String,
		countdown: Number
	},
	components: {
		countdown,
		btn_submit
	},
	emits: [
		'edit'
	],
	data() {
		return {
			loading: false,
			verify_loading: false,
			error: {},
		}
	},
	computed: {
		...mapGetters( 'user', ['auth_user']),

		align_class()
		{
			return ( this.type != 2 ? '' : 'justify-content-end' );
		},

		img_class()
		{
			return ( this.type != 2 ? 'me-3' : 'ms-3 order-1' );
		},

		card_class()
		{
			return ( this.type != 2 ? ( this.type == 3 ? 'alert-danger' : ( this.type == 4 ? 'alert-info small' : 'bg-white border' ) ) : 'alert-secondary' );
		},

		comment_width()
		{
			return ( this.type == 4 ? 100 : 80 );
		}
	},

	mounted()
	{
		this.reset_error()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async resend_verify()
		{
			this.verify_loading = true 

			this.reset_error()

			user_service.resend_verify({
				email: this.email
			}).then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.verify_loading = false
			})
		},

		async reset_password()
		{
			this.reset_loading = true 

			this.reset_error()

			user_service.send_password_reset({
				email: this.email,
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.reset_loading = false
			})
		},

		async edit()
		{
			this.$emit('edit', true)
		}
	}
}
</script>

<style>

</style>
