<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<planning_nav :site="site" :workspace="workspace" />


				<div class="w-100 h-100 position-relative overflow-hidden">

					<div v-if="!page" class="w-100 h-100 d-flex align-items-center justify-content-center">
						<span class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</span>
					</div>

					<form v-else @submit.prevent="update_wp_page_content()" class="w-100 h-100 flex-grow-1 d-flex">

						<div class="overflow-auto border-end" style="flex: 0 0 270px;">

							<div class="d-flex align-items-center small mb-4 small border-bottom p-4" style="height: 24px;">
								<template v-if="!saving">
									<span class="material-icons-outlined me-2 opacity-50">{{ is_unsaved ? 'info' : 'check' }}</span>
									<span class="opacity-50">{{ is_unsaved ? 'Not saved' : 'Saved' }}</span>
								</template>
								<template v-else>
									<div class="d-flex justify-content-center me-2" style="width: 24px;">
										<div class="spinner-border spinner-border-sm " role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>
									Saving
								</template>
							</div>
							
							<tree_page_links :pages="pages_tree" />

						</div>

						<div class="w-100 border-end h-100 d-flex flex-column">
							
							<div class="flex-grow-1 overflow-hidden d-flex flex-column">

								<div class="d-flex p-3 w-100 justify-content-between align-items-center border-bottom">

									<div class="flex-grow-1">
										<form_control
											type="text"
											placeholder="Enter page title"
											name="title"
											group_class="mb-0"
											:error="error"
											v-model="new_page.title"
											v-on:update:modelValue="[submit_delay(), html_to_block()]"
										/>
									</div>
								</div>

								<div v-if="tab == 'content'" class="h-100 w-100 d-flex overflow-hidden">

									<div v-if="is_ai_content" class="h-100 w-50 border-end d-flex align-items-center">
										<div class="w-100 h-75 p-4">

											<div class="setup-chat border rounded-top p-4 overflow-auto mx-auto w-100" ref="scrollContainer" style="max-width: 600px; height: calc( 100% - 90px );">
											
												<chat_message content="Writing a full page of content is a tough job! Would you like me to write a first draft while you put your feet up?" type="1" author="1" />

												<div v-if="ai_step == 2">
													<chat_message content="Yes, please!" :type="2" author="4" />

													<chat_message content="No problem! Before I get started, feel free to provide me with some direction. Let me know when you're ready for me to start." type="1" author="1" :include_countdown="generating" />

													
												</div>

											</div>

											<div class="setup-chat-actions border rounded-bottom border-top-0 overflow-hidden bg-white mx-auto w-100" style="max-width: 600px; height: 90px;">
												<div class="d-flex align-items-center h-100">
													<div class="h-100 flex-grow-1">

														<div v-if="ai_step == 1" class="py-3 d-flex">
															<div class="px-3 pe-1 w-50">
																<btn_submit 
																	label="Yes, please!" 
																	icon="check"  
																	:icon_after="true" 
																	:loading="loading"
																	v-on:click="ai_step = 2"
																/>
															</div>
															<div class="px-3 ps-1 w-50">
																<btn_submit 
																	label="No, I'll do it manually" 
																	icon="close"  
																	:icon_after="true" 
																	:loading="loading"
																	v-on:click="is_ai_content = false"
																/>
															</div>
														</div>
														<div v-else-if="ai_step == 2" class="p-3">
															<btn_submit 
																label="Generate content" 
																icon="check"  
																:icon_after="true" 
																:loading="generating"
																v-on:click="generate_content()"
															/>
														</div>
													</div>
												</div>
											</div>

											<div class="d-flex justify-content-center mt-3">
												<button class="btn btn-link px-0" @click="is_ai_content = false">Go to content editor</button>
											</div>

										</div>

									</div>

									<div v-if="is_ai_content && ai_step == 2" class="h-100 w-50 d-flex align-items-center overflow-auto p-4">
										<div id="planning-page-1" class="planning-page w-100 h-75 mb-0" style="min-height: 1px;">

											<div class="planning-page-content position-relative">

												<form_control
													v-if="!generating"
													icon="chat"
													:key="'page-description-' + new_page.parent_id"
													label="Enter prompt (optional)"
													type="textarea"
													name="original_content"
													placeholder="Add a short description about the purpose of this page. The more detail you add the better job Bella will do creating your content. If there are specific sections you want to be included, then make a note of them. For example: Include the following sections: Introduction. About Us. Services. USPs. Get in Touch."
													:error="error"
													:pages="all_pages"
													v-model="new_page.description"
													group_class="mb-0"
												/>

											</div>

										</div>
									</div>

									<div v-if="!is_ai_content" class="h-100 w-100 p-4 d-flex justify-content-center">
										
										<div class="planning-page w-100 mb-0 p-0 align-items-start h-100 overflow-hidden mx-auto" style="min-height: 1px; max-width: 1200px;">

											<form_control
												:key="'page-content-' + new_page.parent_id"
												type="quill"
												name="original_content"
												group_class="h-100 w-100"
												:error="error"
												:generating="generating"
												:include_delay="true"
												v-model="new_page.original_content"
												:pages="all_pages"
												:is_ai="true"
												:is_format="true"
												v-on:update:modelValue="[submit_delay(), html_to_block()]"
												v-on:update:isAi="[is_ai_content = true, ai_step = 2]"
												v-on:update:isFormat="format_content"
											/>
										</div>
									</div>

								</div>

							</div>
						</div>

					</form>

				</div>

			</div>

		</div>

		<div class="modal fade" :id="'generated-modal'" tabindex="-1" :aria-labelledby="'generated-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">

				<div class="modal-content">

					<div class="modal-header">
						<h5 class="modal-title" :id="'generated-modal-label'">Generated content</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">

						<div v-html="generated_content"></div>
						
					</div>
					<div class="modal-footer d-flex">
						<div class="w-100">
							<form @submit.prevent="use_content()">
								<btn_submit 
									:label="'Use this content'" 
									icon="check"  
									:icon_after="true" 
									:loading="loading" 
								/>
							</form>
						</div>
					</div>

				</div>

			</div>

		</div>

		<div class="modal fade" :id="'format-modal'" tabindex="-1" :aria-labelledby="'format-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="!generating ? 'modal-xl' : ''">

				<div class="modal-content">

					<div class="modal-header" :class="!generating ? 'border-0' : ''">
						<h5 class="modal-title" :id="'format-modal-label'">Format for web</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body p-0">

						<div v-if="!generating">
							<p class="alert alert-info small mb-0 border-start-0 border-end-0 rounded-0">This is a low fidelity wireframe, highlighting how the page will be broken up into different sections.</p>

							<html_to_block_formatted :content="formatted_format" :original_content="new_page.original_content" :page="new_page" />
						</div>
						<div v-else class="my-4 py-4 text-center">
							<span class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</span>
						</div>
						
					</div>
					<div v-if="!generating" class="modal-footer d-flex">
						<div class="w-100">
							<form @submit.prevent="use_content()">
								<btn_submit 
									:label="'Use this content'" 
									icon="check"  
									:icon_after="true" 
									:loading="loading" 
								/>
							</form>
						</div>
					</div>

				</div>

			</div>

		</div>

		<div class="modal fade" :id="'preview-modal'" tabindex="-1" :aria-labelledby="'preview-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">

				<div class="modal-content">

					<div class="modal-header border-0">
						<h5 class="modal-title" :id="'preview-modal-label'">Layout preview</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body p-0">

						<p class="alert alert-info small mb-0 border-start-0 border-end-0 rounded-0">This is a low fidelity wireframe, highlighting how the page will be broken up into different sections.</p>

						<html_to_block_formatted :content="formatted" :original_content="new_page.original_content" :page="new_page" />
						
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import planning_nav from '@/components/planning_nav'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import tree_page_links from '@/components/tree_page_links'
import chat_message from '@/components/chat_message'
import html_to_block_formatted from '@/components/html_to_block_formatted'
import page_service from '@/services/page_service'

export default {
	name: 'site.planning.page',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		form_control,
		btn_submit,
		html_to_block_formatted,
		planning_nav,
		tree_page_links,
		chat_message
	},

	data() {
		return {
			loading: false,
			saving: false,
			site: null,
			error: {},
			pages: [],
			pages_tree: [],
			page: null,
			tab: 'content',
			generated_content: null,
			generating: false,
			is_ai_content: true,
			ai_description: null,
			has_loaded: false,
			formatted: [],
			formatted_format: [],
			all_pages: [],
			delay_timer: null,
			ai_step: 1,
			is_unsaved: false,
			new_page: {
				title: null,
				seo_title: null,
				seo_description: null,
				parent_id: null,
				original_content: '',
				features:[],
				description: ''
			},
			features: [
				{
					key: 'media',
					name: 'Media Gallery',
					description: 'Display a selection of media that when clicked open up full screen.'	
				},
				{
					key: 'cta',
					name: 'Call to Action',	
					description: 'Display a call to action with a default message.'
				},
				{
					key: 'form',
					name: 'Form',	
					description: 'Display a contact or enquiry form.'
				},
				{
					key: 'logos',
					name: 'Client / Partner Logos',
					description: 'Display a carousel of different logos.'	
				},
				{
					key: 'posts',
					name: 'Related Pages',
					description: 'Display pages that share the same parent ID as this page.'	
				},
				{
					key: 'subposts',
					name: 'Sub Pages',
					description: 'Display all child pages of this page with links through to each page.'	
				},
			]
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		has_content()
		{
			return this.new_page.original_content ? this.new_page.original_content.replace(/(<([^>]+)>)/gi, '') : null
		}

	},

	watch: {
		'$route.params.page_id': function()
		{
			this.has_loaded = false

			this.is_ai_content = true 

			this.ai_step = 1

			this.page = null

			this.get_wp_page()
		}
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_wp_page()

		this.get_pages_tree()

		this.get_pages()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_pages_tree()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.pages_tree = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async get_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, '?flatten=true' ).then(( response ) => {

				this.all_pages = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async get_wp_page()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress_page( this.$route.params.workspace_id, this.$route.params.site_id, this.$route.params.page_id ).then(( response ) => {

				this.page = response.data

				if ( this.strip_tags( this.page.original_content ).length ) {
					this.is_ai_content = false
				}

				if ( !this.has_loaded ) {
					this.new_page = {
						title: this.page.post_title,
						seo_title: this.page.seo_title,
						seo_description: this.page.seo_description,
						parent_id: this.page.ID,
						original_content: this.page.original_content,
						features: this.page.features
					}
					this.has_loaded = true
				}

				this.formatted = this.page.original_content_formatted

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		submit_delay()
		{
			this.is_unsaved = true;
			let vm = this
			clearTimeout( this.delay_timer );
			this.delay_timer = setTimeout(function() {
				vm.update_wp_page_content()
			}, 2000);
		},

		async update_wp_page_content()
		{			
			this.reset_error()

			this.loading = true

			this.saving = true;

			await page_service.update_wordpress_content( this.$route.params.workspace_id, this.$route.params.site_id, this.new_page ).then(( response ) => {

				this.pages = response.data

				this.$emit('update:modelValue', this.site)

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.is_unsaved = false
				this.saving = false;
			})
		},

		async html_to_block()
		{			
			this.reset_error()

			this.loading = true

			await page_service.html_to_block( this.$route.params.workspace_id, this.$route.params.site_id, { 
				content: this.new_page.original_content 
			} ).then(( response ) => {

				this.formatted = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async html_to_block_format()
		{			
			this.reset_error()

			await page_service.html_to_block( this.$route.params.workspace_id, this.$route.params.site_id, { 
				content: this.generated_content 
			} ).then(( response ) => {

				this.formatted_format = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.generating = false
			})
		},

		generate_content()
		{
			this.generating = true 

			this.generated_content = null

			this.reset_error()

			page_service.generate_content( this.$route.params.workspace_id, this.$route.params.site_id, { page: this.new_page, description: this.generate_content } ).then(( response ) => {

				this.generated_content = response.data

				var $ = window.$ 

				$('#generated-modal').modal('show');

				this.version++

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.generating = false

			})
		},

		format_content()
		{
			this.generating = true 

			this.generated_content = null

			this.reset_error()

			var $ = window.$ 

			$('#format-modal').modal('show');

			page_service.format_content( this.$route.params.workspace_id, this.$route.params.site_id, { page: this.new_page } ).then(( response ) => {

				this.generated_content = response.data

				this.html_to_block_format()

				this.version++

			}).catch( ( error ) => {

				this.error = error

				this.generating = false

			})
		},

		use_content()
		{
			this.new_page.original_content = this.generated_content
			this.is_ai_content = false
			this.generated_content = null
			var $ = window.$ 
			$('#generated-modal').modal('hide');
			$('#format-modal').modal('hide');
			this.html_to_block();
		},

		strip_tags( html )
		{
			if ( html == null ) {
				return '';
			}
			const doc = new DOMParser().parseFromString(html, 'text/html');
			return doc.body.textContent || '';
		}
	}
}
</script>