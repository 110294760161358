import axios from 'axios'

export const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
});

client.interceptors.response.use(
	( response ) => {
		return response;
	},
	function ( error ) {
		var response = ( error.response && error.response.data ? error.response.data : error );
		response.status = ( error.response && error.response.status ? error.response.status : 500 )
		return Promise.reject( response );
	}
)

export default {

	async index( workspace_id, site_id, payload ) {

		return client.get( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page', payload )

	},

	async html_to_block( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/html-to-block', payload )

	},

	async get_wordpress( workspace_id, site_id, payload ) {

		return client.get( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress' + ( payload ? payload : '' ) )

	},

	async get_wordpress_page( workspace_id, site_id, id ) {

		return client.get( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress/' + id )

	},

	async store_wordpress( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress', payload )

	},

	async update_wordpress( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress', payload )

	},

	async update_wordpress_content( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress/content', payload )

	},

	async update_wordpress_seo( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress/seo', payload )

	},

	async duplicate_wordpress( workspace_id, site_id, id ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress/' + id + '/duplicate' )

	},

	async delete_wordpress( workspace_id, site_id, id ) {

		return client.delete( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/wordpress/' + id )

	},

	async generate_wireframe( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/generate-wireframe', payload )

	},

	async format_content( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/format', payload )

	},

	async generate_content( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/page/generate', payload )

	},

}